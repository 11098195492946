import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, CalendarMonth, Group } from "@mui/icons-material";
import { Fab, ThemeProvider, Tooltip } from "@mui/material";
import getMuiTheme from "../../../theme/TableStyle";
import MUIDataTable from "mui-datatables";
import Loading from "../../../components/layouts/Loading";
import { getElements } from "../../../API/API";
import TableOptions from "../../../components/columns/TableOptions";
import { useNavigate } from "react-router";
import CustomCard from "../../../components/layouts/CustomCard";
import InscritsColumns from "../../../components/columns/InscritsColumns";

export default function InscritsAdmin() {
	const [loading, setLoading] = useState(true);
	const [inscrits, setInscrits] = useState([]);
	const columns = InscritsColumns(inscrits);
	const options = TableOptions("inscrits", inscrits);
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("inscrit");
			setInscrits(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin
			title="Inscrits"
			Icon={Group}
			button={
				<Tooltip title="Afegir inscrit">
					<Fab size="small" onClick={() => navigate("/admin/inscrits/add")}>
						<Add />
					</Fab>
				</Tooltip>
			}
		>
			<CustomCard>
				<Loading loading={loading}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable data={inscrits} columns={columns} options={options} />
					</ThemeProvider>
				</Loading>
			</CustomCard>
		</PageAdmin>
	);
}
